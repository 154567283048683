<template>
  <v-container>
    <router-view class="mt-n6"></router-view>
  </v-container>
</template>

<script>

export default {
  name: 'Fiware',
  components: {
  },
  data: () => ({
  }),
  computed: {
  }
}
</script>
